import { Button, Col, Container, Row, Stack } from 'react-bootstrap';
import logo from './static/logo.png';
import Handshake from './static/Handshake.jpg';
import Img1 from './static/img1.jpg';
import Img2 from './static/img2.jpg';
import Img3 from './static/img3.jpg';

import './App.scss';

function App() {
  return (
    <Container>
      <Stack direction='horizontal' style={{ height: '5rem', justifyContent: 'space-between' }}>
        <Stack direction='horizontal'>
          <img src={logo} alt="logo" width='64px' />
          <h5 style={{ margin: '2rem 0 0 1rem', fontWeight: 500 }}>Eden Advisors</h5>
        </Stack>
        <Stack className='menu' direction='horizontal' style={{ marginTop: '2rem' }}>
          <Button variant='link' style={{ background: 'unset', border: 'none' }}>Home</Button>
          <Button variant='link' style={{ background: 'unset', border: 'none' }}>Welcome</Button>
          <Button variant='link' style={{ background: 'unset', border: 'none' }}>About</Button>
          <Button variant='link' style={{ background: 'unset', border: 'none' }}>Services</Button>
        </Stack>
      </Stack>
      <hr />
      <h1 style={{ fontSize: '50pt', marginTop: '100px' }}>Welcome to Eden Advisors</h1>
      <hr style={{ marginTop: '20px' }} />
      <Row>
        <Col md={6}>
          <Stack>
            <img src={Handshake} alt="Handshake" style={{ padding: '2rem', borderRadius: '3rem' }} />
          </Stack>
        </Col>
        <Col md={6} style={{ borderLeft: '2px solid #a8e8a8' }}>
          <div style={{ padding: '2rem' }}>
            <h2>Unlocking Your Business Potential</h2>
            <p>Eden Advisors is a startup incubator that specializes in providing advisory services, funding, and referrals to subject matter experts. Led by Managing Directors Adam Grill and Evelyn Y. K. Lee, our company focuses on Canadian companies and opportunities, offering excellent access to capital, a strong management team, and well-structured business deals.</p>
            <Button variant='success'>Learn More</Button>
          </div>
        </Col>
      </Row>

      <div style={{ backgroundColor: '#194128', borderRadius: '1rem' }}>
        <h1 style={{ color: 'white', fontSize: '50pt', fontWeight: 500, padding: '2rem' }}>About Us</h1>
        <Row className='inner-box' style={{ backgroundColor: '#458933', borderRadius: '2rem' }}>
          <Col md={6}>
            <div style={{ padding: '2rem', color: 'white' }}>
              <h2>Our Expertise</h2>
              <p>Eden Advisors is a startup incubator that specializes in providing advisory services, funding, and referrals to subject matter experts. Led by Managing Directors Adam Grill and Evelyn Y. K. Lee, our company focuses on Canadian companies and opportunities, offering excellent access to capital, a strong management team, and well-structured business deals.</p>
              <Button variant='primary'>Learn More</Button>
            </div>
          </Col>
          <Col md={6}>
            <Stack style={{ justifyContent: 'center' }}>
              <div style={{ textAlign: 'center' }}>
                <video className='my-video' preload="auto" muted={true} loop={true} autoPlay={true} src="/img/video.mp4"
                  style={{
                    objectFit: 'cover',
                    objectPosition: 'center center',
                    opacity: 1
                  }}></video>
              </div>
            </Stack>
          </Col>
        </Row>

        <Stack direction='horizontal' style={{ backgroundImage: 'url(/img/bg.jpg)', justifyContent: 'end', backgroundSize: 'cover', marginTop: '2rem', marginBottom: '2rem', borderRadius: '2rem' }}>
          <div className='img-text' style={{ color: 'white', textAlign: 'center' }}>
            <h1>Our Services</h1>
            <p>Investing in Your Success</p>
            <p className='p2' >Eden Advisors empowers startups and small businesses with essential tools for success. Our range of services is ever-expanding; discover what we offer below. If you have a specific need not listed, we'd love to hear from you and explore new solutions together.</p>
          </div>
        </Stack>

        <Row className='inner-box' style={{ backgroundColor: '#458933', borderStartStartRadius: '2rem', borderStartEndRadius: '2rem' }}>
          <Col md={6} style={{ padding: '0', height: '100%' }}>
            <img src={Img1} alt="Img1" style={{ width: '100%', height: '100%', borderStartStartRadius: '2rem' }} />
          </Col>
          <Col md={6}>
            <div style={{ padding: '2rem', color: 'white' }}>
              <h2>Advisory Services</h2>
              <p>We advise, build, and fund startups.</p>
              <p>Our advisory services are one of our most popular offerings. With our expertise, we ensure all details are simple, seamless and handled in a timely manner. Whenever you work with Eden Advisors, you can trust that you're in great hands.</p>
            </div>
          </Col>
        </Row>

        <Row className='inner-box reversable' style={{ backgroundColor: '#458933' }}>
          <Col md={6} sm={12}>
            <div style={{ padding: '2rem', color: 'white' }}>
              <h2>Funding Opportunities</h2>
              <p>Frequently utilized by most of our clients, our funding opportunities have been essential to success on many occasions. When it comes to all of our services, you can count on us to take care of your every need. We pride ourselves on our exceptional customer service, and guarantee you'll love working with our team. Let us know how we can assist you today.</p>
              <p>Our investment portfolio focuses on Canadian companies because we believe there is great talent that deserves a platform for sustainable growth. </p>
            </div>
          </Col>
          <Col md={6} sm={12} style={{ padding: '0', height: '100%' }}>
            <img src={Img2} alt="Img2" style={{ height: '100%', width: '100%' }} />
          </Col>
        </Row>

        <Row className='inner-box' style={{ backgroundColor: '#458933', borderEndStartRadius: '2rem', borderEndEndRadius: '2rem' }}>
          <Col md={6} sm={12} style={{ padding: '0', height: '100%' }}>
            <img src={Img3} alt="Img3" style={{ width: '100%', height: '100%', borderEndStartRadius: '2rem' }} />
          </Col>
          <Col md={6} sm={12}>
            <div style={{ padding: '2rem', color: 'white' }}>
              <h2>Subject Matter Expert Referrals</h2>
              <p>
                Subject Matter Expert Referrals
                We want all of our clients to experience the impressive level of professionalism when working with Eden Advisors. All of our services exist to make your life easier and stress-free. You can trust us to connect you with subject matter experts and accredited investors who can help take your business to the next level.</p>
              <p>We work with professionals from a variety of areas, including but not limited to: business, engineering, finance, intellectual property law, and medicine. </p>
            </div>
          </Col>
        </Row>

        <Button variant='success' style={{ margin: '2rem' }}>Learn More</Button>
      </div>

      <Row style={{ textAlign: 'center', padding: '5rem 0' }}>
        <Col lg={3} sm={0}></Col>
        <Col lg={6}>
          <h1>Enhance Your Policy Impact</h1>
          <p style={{ paddingTop: '2rem' }}>Eden Advisors provides non-profit advocacy organizations with expert management guidance. With a focus on operational decisions and governance processes, Eden helps organizations enhance their policy impact.</p>
        </Col>
        <Col lg={3} sm={0}></Col>
      </Row>
      <hr style={{ marginTop: '20px' }} />
      <Row>
        <Col md={6}>
          <h5 style={{ padding: '10px 0', fontWeight: 'bold' }}>Contact</h5>
          <p style={{ margin: '1px' }}>+1 (604) 327-1111</p>
          <p style={{ margin: '1px' }}>info@edenadvisors.ca</p>
        </Col>
        <Col md={6}>
          <h5 style={{ padding: '10px 0', fontWeight: 'bold' }}>Address</h5>
          <p style={{ margin: '1px' }}>207 Bank Street, Suite 180, Ottawa ON</p>
          <p style={{ margin: '1px' }}>Canada, K2P 2N2</p>
        </Col>
      </Row>
      <hr style={{ marginTop: '20px' }} />

      <div style={{ marginTop: '5rem', padding: '5rem', backgroundColor: 'black', color: 'white', textAlign: 'center', borderRadius: '2rem' }}>
        <h3>Testimonials</h3>
        <h5 style={{ paddingTop: '2rem', fontStyle: 'italic' }}>"Allie is a trusted advisor to senior executives working on some of the most challenging public policy issues."</h5>
        <div style={{ marginTop: '2rem', color: 'orange' }}>Max Moncaster</div>
        <div style={{ color: 'orange' }}>President of Midwest Blockchain Institute</div>
      </div>

      <Row style={{ marginTop: '5rem' }}>
        <h2>Stay informed, join our newsletter</h2>
        <a className='btn btn-success' href="mailto:info@edenadvisors.ca" style={{ width: '200px', marginLeft: '1rem' }}>
          Send Email
        </a>
      </Row>
      <p style={{ width: '100%', color: 'lightgray', textAlign: 'center', paddingTop: '100px' }}>© 2024 Eden Advisors, LLC</p>
    </Container>

  );
}

export default App;
